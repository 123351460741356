import storage from 'good-storage'
import { Dialog, Notify } from 'vant'
import { random } from '@/utils/index'
import md5 from 'md5'
const MUSIC_CURRENT_TIME = '__currentTime__'
const MUSIC_ALL_TIME = '__allTime__'
const MUSIC_BUFFER_TIME = '__bufferTime__'
const CURRENT_PLAYLIST_NAME = '__currentPlayListName__'
// 默认列表
const MusicListJsonDefault = require('@/assets/json/music.json')
// 周杰伦专区
const MusicListJsonZjl = require('@/assets/json/zjl.json')
// i like you 专区
const MusicListJsonlike = require('@/assets/json/like-you.json')

// 歌单列表枚举
const playListFiles = [
  { name: '默认列表', key: 'default', list: MusicListJsonDefault },
  { name: '周杰伦专场', key: 'zjl', list: MusicListJsonZjl },
  { name: 'I Like You', key: 'like', list: MusicListJsonlike }
]

const getMusicListStorageName = (name) => {
  return `musicList_v1.0.1_${name}`
}

const state = {
  musicList: storage.get(getMusicListStorageName('default')), // 播放列表
  sourceRef: null, // 当前音乐源
  currentTime: storage.get(MUSIC_CURRENT_TIME) || 0, // 当前播放时间
  allTime: storage.get(MUSIC_ALL_TIME) || 0, // 总计时长
  bufferTime: storage.get(MUSIC_BUFFER_TIME) || 0, // 缓存时长
  playListFiles: playListFiles,
  currentPlayListName: storage.get(CURRENT_PLAYLIST_NAME) || 'default'
}
const mutations = {
  SET_STATE(state, { value, key }) {
    if (typeof key === "string") {
      state[key] = value;
    }
  }
}
const actions = {
  // 初始化音乐列表
  initMusicList({ commit, state, dispatch, rootState }, isRewrite = false) {
    const storageData = storage.get(getMusicListStorageName(state.currentPlayListName))

    if (storageData) {
      // 本地缓存中存在, 直接取出来
      dispatch('setMusicList', storageData)
    } else if (!state.musicList || isRewrite) {
      const currentList = state.playListFiles.find(item => item.key === state.currentPlayListName)
      const currntJson = JSON.parse(JSON.stringify(currentList && currentList.list || MusicListJsonDefault))
      let resList = new Array()

      while (currntJson.length) {
        const chooseIndex = random(0, currntJson.length - 1)
        const chooseItem = currntJson[chooseIndex]
        resList.push({
          ...chooseItem,
          id: md5(chooseItem.title)
        })
        currntJson.splice(chooseIndex, 1)
      }
      dispatch('setMusicList', resList)
    }

    if (JSON.stringify(rootState.music.currentSource) === "{}" && state.musicList.length) {
      dispatch('setCurrentSource', state.musicList[0])
    }
  },

  // 切换歌单
  async togglePlayList({ rootState, commit, state, dispatch }, nameItem) {
    commit('SET_STATE', { key: 'currentPlayListName', value: storage.set(CURRENT_PLAYLIST_NAME, nameItem.key) })
    await dispatch('initMusicList', true)
    Notify({
      type: 'success',
      message: `已切换至歌单<${nameItem.name}>`
    })
    await dispatch("resetResetList")
  },

  // 设置音乐播放列表
  setMusicList({ commit, state }, data) {
    commit('SET_STATE', { key: 'musicList', value: storage.set(getMusicListStorageName(state.currentPlayListName), data) })
  },

  // 设置当前音乐元素
  setCurrentMusic({ commit }, data) {
    commit('SET_STATE', { key: 'sourceRef', value: data })
  },

  // 设置当前播放时间
  setCurrentTime({ commit }, data) {
    commit('SET_STATE', { key: 'currentTime', value: storage.set(MUSIC_CURRENT_TIME, data) })
  },

  // 设置总计时长
  setAllTime({ commit }, data) {
    commit('SET_STATE', { key: 'allTime', value: storage.set(MUSIC_ALL_TIME, data) })
  },

  // 设置缓存长度
  setBufferTime({ commit }, data) {
    commit('SET_STATE', { key: 'bufferTime', value: storage.set(MUSIC_BUFFER_TIME, data) })
  },

  // 校验歌曲是否已经存在
  validateMusicExit({ commit, state }, data) {
    const dataTitle = data.title.toLocaleLowerCase().trim()
    const currentMusic = state.musicList || []
    const musicKeyList = currentMusic.map(item => item.title.toLocaleLowerCase().trim())
    return musicKeyList.includes(dataTitle)
  },

  // 新增歌曲至列表
  async addMusicList({ commit, dispatch }, data) {
    const isExit = await dispatch('validateMusicExit', data)
    if (isExit) {
      return Notify({
        type: 'warning',
        message: `<${data.title}> 已存在`
      })
    }
    state.musicList.push({
      ...data,
      id: md5(data.title)
    })
    await dispatch('setMusicList', state.musicList)
    Notify({
      type: 'success',
      message: `<${data.title}> 已添加至列表`
    })
  },

  // 删除歌曲列表
  removeMusicList({ commit, state, dispatch, rootState }, item) {
    Dialog.confirm({
      message: `确认删除歌曲 <${item.title}>？`
    }).then(() => {
      const currentIndex = state.musicList.findIndex((m) => m.id === item.id)
      state.musicList.splice(currentIndex, 1)
      dispatch('setMusicList', state.musicList)
      Notify({
        type: 'success',
        message: `<${item.title}> 已删除`
      })

      if (item.id === rootState.music.currentSource.id && state.musicList.length) {
        dispatch('setCurrentSource', state.musicList[0])
      }
    })
  },

  // 编辑歌曲信息
  async updateMusicInfo({ commit, state, dispatch }, data) {
    const currentIndex = state.musicList.findIndex((item) => item.id === data.id)
    if (currentIndex > -1) {
      state.musicList.splice(currentIndex, 1, data)
      await dispatch('setMusicList', state.musicList)
    } else {
      await dispatch('addMusicList', data)
    }
  },

  // 根据id获取歌曲信息
  getMusicInfoById({ commit, state }, id) {
    return state.musicList.find((item) => item.id === id)
  },

  // 获取当前歌单信息
  getCurrentPlayListInfo({ commit, state }, nameKey) {
    const currentPlayListName = nameKey || state.currentPlayListName
    const playListFiles = state.playListFiles
    return playListFiles.find(item => item.key === currentPlayListName)
  },

  // 批量导入歌曲列表
  async importMusicList({ commit, dispatch }, data) {
    const newList = data.map((item, index) => ({
      ...item,
      id: md5(item.title)
    }))
    state.musicList = state.musicList.concat(newList)
    await dispatch('setMusicList', state.musicList)
    Notify({
      type: 'success',
      message: `选中歌曲已添加至列表`
    })
  },

  // 清空歌曲列表
  async clearMusicList({ commit, dispatch }) {
    await Dialog.confirm({
      message: `确认清空播放列表？可通过手动添加、上传json文件、清除缓存重置播放列表`
    })
    await dispatch('setMusicList', [])
    await dispatch('setCurrentSource', {})
    Notify({
      type: 'success',
      message: '播放列表已清空'
    })
  }
}
const getters = {
  musicList: state => state.musicList || [],
  sourceRef: state => state.sourceRef,
  currentTime: state => state.currentTime,
  allTime: state => state.allTime,
  bufferTime: state => state.bufferTime,
  playListFiles: state => state.playListFiles,
  currentPlayListName: state => state.currentPlayListName
}

export default {
  state,
  mutations,
  actions,
  getters
}
