import storage from 'good-storage'
import { Notify } from 'vant';
import { PLAY_LIST_TYPE_LIST, PLAY_LIST_TYPE_ICON_MAP } from '@/utils/const'
import { random, NumFixed } from '@/utils/index'
const CURRENT_SOURCE_KEY = '__currentSource__'
const PLAY_LIST_TYPE_KEY = '__playListType__'
const PLAY_VOLUME_KEY = '__playVolume__'
const RESET_PLAY_LIST_KEY = '__resetPlayList__'

const state = {
  playStatus: '0', // 播放状态, 0-暂停, 1-播放
  playVolume: storage.get(PLAY_VOLUME_KEY) || 1, // 默认1, 步级 0.05
  initFirstPlay: false, // 是否手动点击过播放, 不然调用play执行不了
  playListType: storage.get(PLAY_LIST_TYPE_KEY) || 'inOrder', // 播放列表顺序类型, random-随机 inOrder-顺序 singleCycle-单曲循环
  currentSource: storage.get(CURRENT_SOURCE_KEY) || {}, // 当前音乐资源信息 item
  // 剩余待播放列表
  resetPlayList: storage.get(RESET_PLAY_LIST_KEY) || []
}
const mutations = {
  SET_STATE(state, { value, key }) {
    if (typeof key === "string") {
      state[key] = value;
    }
  }
}
const actions = {
  // 播放 / 暂停音乐
  async playMusic({ commit, state, rootState, dispatch }, data) {
    const musicList = rootState.source.musicList ? rootState.source.musicList : []
    if (musicList.length === 0) {
      return Notify({
        type: 'warning',
        message: '音乐列表无资源'
      })
    }
    if (!rootState.source.sourceRef) {
      return Notify({
        type: 'warning',
        message: '当前资源未加载完成'
      })
    }
    let status = data ? data : state.playStatus === '1' ? '0' : '1'

    // 歌曲对象信息
    const currentSource = state?.currentSource || {}

    if (status === '1') {
      document.title = `${currentSource?.title || "暂无歌曲名称"} - 随辛听`
      rootState.source.sourceRef.play()
      if ('mediaSession' in navigator) {
        // @ts-ignore
        navigator.mediaSession.playbackState = "playing"
      }
    } else {
      document.title = `随辛听`
      rootState.source.sourceRef.pause()
      if ('mediaSession' in navigator) {
        // @ts-ignore
        navigator.mediaSession.playbackState = "paused"
      }
    }
    commit('SET_STATE', { key: 'playStatus', value: status })
    await dispatch('setMediaMetadata', state.currentSource)
  },

  // 设置音量大小
  setPlayVolume({ commit }, volume = 1) {
    if (volume < 0) {
      volume = 0
    } else if (volume > 1) {
      volume = 1
    }
    commit('SET_STATE', { key: 'playVolume', value: storage.set(PLAY_VOLUME_KEY, volume) })
    Notify({
      type: 'success',
      message: `音量调整至 ${NumFixed(volume * 100)}`
    })
  },

  // 上调, 下调音量
  async togglePlayVolume({ commit, state, dispatch }, isAdd = false) {
    const step = 0.05
    const currentVolume = state.playVolume - (isAdd ? -1 : 1) * step
    await dispatch('setPlayVolume', NumFixed(currentVolume))
  },

  resetResetList ({ commit, state, rootState }, list) {
    if (state.playListType === "random") {
      // 重置随机列表
      const musicList = rootState.source.musicList ? rootState.source.musicList : []
      commit('SET_STATE', { key: 'resetPlayList', value: storage.set(RESET_PLAY_LIST_KEY, musicList) })
    }
  },

  // 随机选下标处理
  updateResetList({ commit, state, rootState }, currentInfo) {
    // 先排除当前数据
    let newList = state.resetPlayList || []
    const currentInfoIndex = newList.findIndex(item => item.id === currentInfo.id)
    newList.splice(currentInfoIndex, 1)
    commit('SET_STATE', { key: 'resetPlayList', value: storage.set(RESET_PLAY_LIST_KEY, newList) })

    // 源数据列表
    const musicList = rootState.source.musicList ? rootState.source.musicList : []

    if (!newList || newList.length === 0) {
      newList = JSON.parse(JSON.stringify(musicList))
    }
    const nextIndex = random(0, newList.length - 1)
    const nextInfo = newList[nextIndex]
    const nextNewIndex = musicList.findIndex(item => item.id === nextInfo.id)

    commit('SET_STATE', { key: 'resetPlayList', value: storage.set(RESET_PLAY_LIST_KEY, newList) })
    return nextNewIndex > -1 ? nextNewIndex : 0
  },

  // 上一首
  async toggleMusic({ commit, state, rootState, dispatch }, { isNext = true, isAutoNext = false }) {
    await dispatch('setCurrentTime', 0)
    await dispatch('setAllTime', 0)
    const musicList = rootState.source.musicList ? rootState.source.musicList : []
    if (musicList.length === 0) {
      return Notify({
        type: 'warning',
        message: '音乐列表无资源'
      })
    }
    let nextIndex
    let currentIndex = musicList.findIndex((item) => item.id === state.currentSource.id)
    currentIndex = currentIndex === -1 ? 0 : currentIndex
    switch (state.playListType) {
      case 'random': // 随机播放
        nextIndex = await dispatch("updateResetList", musicList[currentIndex])
        console.log("nextIndex", nextIndex);
        

        break;
      case 'singleCycle': // 单曲循环
        if (isAutoNext) { // 自动播放完成后
          nextIndex = currentIndex
          break
        }
      case 'inOrder': // 顺序播放
      default:
        nextIndex = currentIndex
        if (isNext) { // 下一首
          nextIndex = nextIndex + 1
        } else { // 上一首
          nextIndex = nextIndex - 1
        }

        if (nextIndex < 0) {
          nextIndex = musicList.length - 1
        }

        if (nextIndex > musicList.length - 1) {
          nextIndex = 0
        }
        break;
    }

    await dispatch('setCurrentSource', musicList[nextIndex])
  },

  // 切换播放列表类型
  async togglePlayListType({ commit, state, rootState, dispatch }, type) {
    let setType = 'inOrder'
    if (type && PLAY_LIST_TYPE_LIST.includes(type)) {
      setType = type
    } else {
      const currentIndex = PLAY_LIST_TYPE_LIST.indexOf(state.playListType)
      if (currentIndex !== -1 && currentIndex + 1 < PLAY_LIST_TYPE_LIST.length) {
        setType = PLAY_LIST_TYPE_LIST[currentIndex + 1]
      }
    }
    commit('SET_STATE', { key: 'playListType', value: storage.set(PLAY_LIST_TYPE_KEY, setType) })
    Notify({
      type: 'success',
      message: `已切换至${PLAY_LIST_TYPE_ICON_MAP[setType].desc}模式`
    })
    await dispatch("resetResetList")
  },

  // 设置当前音乐元素
  setCurrentSource({ commit }, data) {
    commit('SET_STATE', { key: 'currentSource', value: storage.set(CURRENT_SOURCE_KEY, data) })
  },

  // 设置初始化播放点击状态
  setInitFirstPlay({ commit }, bol) {
    commit('SET_STATE', { key: 'initFirstPlay', value: bol })
  }
}
const getters = {
  playStatus: (state) => state.playStatus,
  playVolume: state => state.playVolume,
  initFirstPlay: state => state.initFirstPlay,
  currentSource: state => state.currentSource,
  playListType: state => state.playListType,
  isLoop: state => state.playListType === 'singleCycle'
}

export default {
  state,
  mutations,
  actions,
  getters
}
