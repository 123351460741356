import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/App.vue'
import HomeRoutes from '@/router/home'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  ...HomeRoutes,
  {
    path: '/building',
    name: 'building',
    component: () => import(/* webpackChunkName: "building" */ '@/views/error/building.vue'),
    meta: {
      title: '页面建设中, 敬请期待'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/error/404.vue'),
    meta: {
      title: '页面不存在'
    }
  }
]

const router = createRouter({
  // @ts-ignore
  history: createWebHistory(window.__POWERED_BY_QIANKUN__ ? '/sin-music' : process.env.BASE_URL),
  routes
})

router.beforeEach((from, to, next) => {
  console.log(to.path, from.path);
  next()
})

router.afterEach(() => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
})

export default router
