import { RouteRecordRaw } from 'vue-router'

const home: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'listen',
    component: () => import(/* webpackChunkName: "listen" */ '@/views/listen/index.vue')
  },
  {
    path: '/add-music',
    name: 'add-music',
    component: () => import(/* webpackChunkName: "add-music" */ '@/views/add-music/index.vue')
  },
  {
    path: '/import-music',
    name: 'import-music',
    component: () => import(/* webpackChunkName: "import-music" */ '@/views/import-music/index.vue')
  },
  {
    path: '/help-me',
    name: 'help-me',
    component: () => import(/* webpackChunkName: "help-me" */ '@/views/help-me/index.vue')
  },
  {
    path: '/search-address',
    name: 'search-address',
    component: () => import(/* webpackChunkName: "search-address" */ '@/views/search-address/index.vue')
  }
]

export default home