<template>
  <div class="home">
    <div class="page-content">
      <router-view />
    </div>

    <!-- 音源组件, 可页面跳转 -->
    <song-source v-if="songVisible" :src="currentSource.audioUrl" />
  </div>
</template>

<script>
import {
  ref,
  computed
} from 'vue'
import { useStore } from 'vuex'
import SongSource from '@/components/song-source'
import { useRoute } from 'vue-router'

export default {
  name: 'Home',

  components: {
    SongSource
  },

  setup () {
    const store = useStore()
    const route = useRoute()
    const currentSource = computed(() => store.getters.currentSource)

    store.dispatch('initMusicList')
    // 音乐播放黑名单
    const blackList = ['search-address']
    const songVisible = computed(() => {
      return !blackList.includes(route.name)
    })

    return {
      currentSource,
      songVisible
    }
  }
}
</script>