// 手机号校验
export function MobileReg(mobile) {
  return /^1[3|4|5|7|8][0-9]{9}$/.test(mobile)
}

// 身份证校验
export function IdNoReg(idNo) {
  return /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(idNo)
}

export function IsLinkUrl(url) {
  return /(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/.test(url)
}

// 是否微信中打开
export function IsWechat() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') > -1
}

// 是否在ios系统移动端打开
export const IsIosMobile = /(iPhone|iPad|iPod)/i.test(navigator.userAgent)