import './public-path'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vConsole from 'vconsole'
// import { useVant } from '@/plugins/vant'
import '@/assets/scss/index.scss'

let app
let vConsoleIns

// 独立运行时
// @ts-ignore
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

function render(props: any = {}) {
  const { container } = props;
  // 调试控制台
  if (process.env.VUE_APP_VCONSOLE === '1') {
    vConsoleIns = new vConsole()
  }
  app = createApp(App)
  app.use(store)
  app.use(router)
  // useVant(app)
  app.mount(container ? container.querySelector('#app') : '#app')
}

export async function bootstrap() {
  console.log('[vue] vue app bootstraped');
}

export async function mount(props) {
  console.log('[vue] props from main framework', props);
  render(props);
  console.log('vConsoleIns', vConsoleIns);
}

export async function update(props: any = {}) {
  console.log('ipdate props', props);
}

export async function unmount() {
  console.log('[vue] app unmount');
  vConsoleIns.destroy()
  app.unmount();
}