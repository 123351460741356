<template>
  <div class="song-source-component">
    <audio ref="audioRef" preload="auto" :src="src" :loop="isLoop" :volume="playVolume" @play="audioPlay"
      @pause="audioPause" @playing="audioPlaying" @waiting="audioWaiting" @timeupdate="audioUpdateTime"
      @canplay="audioCanPlay" @ended="audioEnd" @error="audioError" @prev="togglePreNext(false)"
      @next="togglePreNext(true)">
      浏览器不支持播放
    </audio>
  </div>
</template>

<script>
import { Notify, Toast } from 'vant'
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch
} from 'vue'
import { useStore } from 'vuex'
import { IsIosMobile } from '@/utils/regs'

export default defineComponent({
  name: 'song-source',

  props: {
    src: String
  },

  setup (props, context) {
    const state = reactive({
      musicReady: false,
      audioLoadingIns: null
    })
    const audioRef = ref(null)
    const store = useStore()

    const playVolume = computed(() => store.getters.playVolume)
    const musicList = computed(() => store.getters.musicList)
    const isLoop = computed(() => store.getters.isLoop || musicList.value.length === 1)
    const initFirstPlay = computed(() => store.getters.initFirstPlay)
    const currentSource = computed(() => store.getters.currentSource)

    onMounted(() => {
      audioRef.value.load()
      document.addEventListener('keyup', keyupEventListener)
      document.addEventListener('click', touchstartEventListener, true)
      // 监听媒体设备变化, 暂停音乐处理
      navigator.mediaDevices && (navigator.mediaDevices.ondevicechange = (e) => {
        // console.log('ondevicechange', e);
        store.dispatch('playMusic', '0')
      })
    })

    onBeforeUnmount(() => {
      document.removeEventListener('keyup', keyupEventListener)
    })

    watch(() => props.src, (newVal) => {
      // console.log('src-change', newVal);
      state.musicReady = false
      if (newVal) {
        // audioRef.value.load()
        // console.log('IsIosMobile', IsIosMobile);
        IsIosMobile ? nextTick(() => {
          audioRef.value.load()
        }) : audioRef.value.load()
      } else { // 清空列表等, 未获取到歌曲地址
        // console.log('clear, 无连接地址');
        store.dispatch('setCurrentTime', 0)
        store.dispatch('setAllTime', 0)
      }
    })

    // 音频可播放
    function audioCanPlay () {
      if (!audioRef.value) {
        return
      }
      store.dispatch('setCurrentTime', audioRef.value.currentTime)
      store.dispatch('setAllTime', audioRef.value.duration)
      store.dispatch('setCurrentMusic', audioRef.value)
      state.musicReady = true
      // 自动播放下一首判断, 首次自动播放不生效
      if (initFirstPlay.value) {
        store.dispatch('playMusic', "1")
      }

      // 更新之前加载失败的歌曲状态
      if (currentSource.value.loadError) {
        const tagErrorInfo = {
          ...currentSource.value,
          loadError: false
        }
        store.dispatch('updateMusicInfo', tagErrorInfo)
      }
    }

    // 更新时间信息
    function audioUpdateTime () {
      console.log('audioUpdateTime');
      if (!state.musicReady) return
      store.dispatch('setCurrentTime', audioRef.value.currentTime)
      store.dispatch('setBufferTime', audioRef.value.buffered.end(audioRef.value.buffered.length - 1))
    }

    // 播放到底处理
    function audioEnd () {
      console.log('audioEnd');
      store.dispatch('toggleMusic', { isNext: true, isAutoNext: true })
    }

    // 加载错误, 下一首
    function audioError () {
      console.log('audioError');
      const tagErrorInfo = {
        ...currentSource.value,
        loadError: true
      }
      store.dispatch('updateMusicInfo', tagErrorInfo)
      Notify({
        type: 'danger',
        message: '资源加载失败, 自动播放下一首',
        onClose: () => {
          store.dispatch('toggleMusic', { isNext: true })
        }
      })
    }

    // 播放
    function audioPlay () {
      console.log('audioPlay');
    }

    // 暂停处理
    function audioPause () {
      console.log('audioPause');
    }

    // 播放中处理
    function audioPlaying () {
      console.log('audioPlaying');
      state.audioLoadingIns && state.audioLoadingIns.clear()
    }

    // 播放暂时性缺少数据, 暂停播放
    function audioWaiting () {
      console.log('audioWaiting');
      state.audioLoadingIns && state.audioLoadingIns.clear()
      state.audioLoadingIns = Toast.loading({
        message: '缓冲中...'
      })
    }

    // 耳机上下曲事件
    function togglePreNext (isNext) {
      console.log('切换上下首事件=>', isNext);
      // store.dispatch('toggleMusic', { isNext: isNext })
    }

    // 添加监听事件
    function keyupEventListener (e) {
      // console.log('===============');
      // console.log('keyup-e =>', e);
      // console.log('keyup-code =>', e.keyCode);
      const { keyCode, ctrlKey } = e
      if (ctrlKey && keyCode === 37) { // ctrl + leftArrow -> 下一首
        store.dispatch('toggleMusic', { isNext: false })
      } else if (ctrlKey && keyCode === 39) { // ctrl + rightArrow -> 上一首
        store.dispatch('toggleMusic', { isNext: true })
      } else if (keyCode === 32) { // 空格键 -> 暂停/播放切换
        store.dispatch('playMusic')
      } else if (ctrlKey && keyCode === 38) { // ctrl + topArrow -> 音量加
        store.dispatch('togglePlayVolume', true)
      } else if (ctrlKey && keyCode === 40) { // ctrl + bottomArrow -> 音量减
        store.dispatch('togglePlayVolume', false)
      }
    }

    // 管理首次加载播放 点击过事件状态值处理
    function touchstartEventListener (e) {
      console.log('touchstartEventListener', e);
      if (!initFirstPlay.value) {
        store.dispatch('setInitFirstPlay', true)
        document.removeEventListener('click', touchstartEventListener, true)
      }
    }

    return {
      state,
      audioRef,
      isLoop,
      playVolume,
      audioCanPlay,
      audioUpdateTime,
      audioEnd,
      audioError,
      audioPlay,
      audioPause,
      audioPlaying,
      audioWaiting,
      togglePreNext
    }
  }
})
</script>
<style lang="scss" scoped>
.song-source-component {
  color: #000;
  visibility: hidden;
}
</style>
