// @ts-nocheck
import { Notify } from "vant"
import { saveAs } from 'file-saver'
import { IsWechat } from './regs'

export function timeFormat(tem, fmt = 'yyyy-MM-dd HH:mm:ss') {
  let ret
  const date = tem ? new Date(tem) : new Date()
  const opt = {
    'y+': date.getFullYear().toString(), // 年
    'M+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'H+': date.getHours().toString(), // 时
    'm+': date.getMinutes().toString(), // 分
    's+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
    }
  }
  return fmt
}

// 秒转化时间格式
export function secondsFormat(s) {
  let m;
  m = Math.floor(s / 60);
  //计算秒
  //算法：取得秒%60的余数，既得到秒数
  s = Math.floor(s % 60);
  //将变量转换为字符串
  m += '';
  s += '';
  //如果只有一位数，前面增加一个0
  m = (m.length == 1) ? '0' + m : m;
  s = (s.length == 1) ? '0' + s : s;
  return m + ':' + s;
}

// 生成 [min, max] 的随机数,
export function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

// filterValue除该值之外的随机数
export function randomFilter(min, max, filterValue) {
  if (min === max) {
    return min
  }
  let current = random(min, max)
  if ((typeof filterValue === 'number') && filterValue >= min && filterValue <= max && current === filterValue) {
    current = randomFilter(min, max, filterValue)
  }
  return current
}

// 全屏处理
export function ToggleFullScreen(isCloseFull = false, el: any = document.body || document.documentElement) {
  const isFullscreen = document.fullscreenElement;
  if (!isFullscreen && !isCloseFull) {
    FullScreen(el)
  } else {
    isFullscreen && ExitFullScreen()
  }
}

// 进入全屏
export function FullScreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (element.oRequestFullscreen) {
    element.oRequestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullScreen();
  }
}

// 退出全屏
export function ExitFullScreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.oRequestFullscreen) {
    document.oCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
}

// 粘贴板复制
export function CopyText(text) {
  const oInput = document.createElement('textarea')
  oInput.value = text
  document.body.appendChild(oInput)
  oInput.select()
  oInput.setSelectionRange(0, text.length)
  document.execCommand('Copy')
  oInput.remove()
  Notify({
    type: 'success',
    message: '复制成功'
  })
}

// 图片复制到粘贴板
export function CopyImg(base64) {
  const img = document.createElement('img')
  img.src = base64
  img.onload = () => {
    document.body.appendChild(img)
    let selection = window.getSelection();
    selection && selection.removeAllRanges();
    const range = document.createRange();
    range.selectNode(img);
    selection && selection.addRange(range);
    document.execCommand('Copy')
    img.remove()
    Notify({
      type: 'success',
      message: '图片已复制至粘贴板'
    })
  }
}

// 保存文件
export function SaveFile(content, fileName) {
  if (IsWechat()) {
    return Notify({
      type: 'warning',
      message: `请在浏览器中下载歌曲列表`
    })
  }
  const blob = new Blob([content])
  saveAs(blob, fileName)
  Notify({
    type: 'success',
    message: `文件<${fileName}>已保存`
  })
}

// base64转码
export function Base64Encode(data) {
  return window.btoa(encodeURIComponent(data))
}

// base64解码
export function Base64Decode(str) {
  return decodeURIComponent(window.atob(str))
}

// 浮点数精准处理
export function NumFixed(value, n = 2) {
  return Math.round(value * Math.pow(10, n)) / Math.pow(10, n)
}