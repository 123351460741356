// @ts-nocheck
const state = {

}

const mutations = {
  SET_STATE(state, { value, key }) {
    if (typeof key === "string") {
      state[key] = value;
    }
  }
}

const actions = {
  setMediaMetadata({ commit, dispatch }, data) {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: data.title,
        artist: data.author,
        album: '',
        artwork: data.picUrl ? [{ src: data.picUrl, type: 'image/png' }] : []
      });

      navigator.mediaSession.setActionHandler('play', () => {
        console.log('navigator.mediaSession.play');
        dispatch('playMusic', '1')
      });
      navigator.mediaSession.setActionHandler('pause', () => {
        console.log('navigator.mediaSession.pause');
        dispatch('playMusic', '0')
      });
      navigator.mediaSession.setActionHandler('stop', () => {
        console.log('navigator.mediaSession.stop');
        dispatch('playMusic', '0')
      });
      // navigator.mediaSession.setActionHandler('seekbackward', () => {
      //   console.log('navigator.mediaSession.seekbackward');
      // });
      // navigator.mediaSession.setActionHandler('seekforward', () => {
      //   console.log('navigator.mediaSession.seekforward');
      // });
      // navigator.mediaSession.setActionHandler('seekto', () => {
      //   console.log('navigator.mediaSession.seekto');
      // });
      navigator.mediaSession.setActionHandler('previoustrack', () => {
        console.log('navigator.mediaSession.previoustrack');
        dispatch('toggleMusic', { isNext: false })
      });
      navigator.mediaSession.setActionHandler('nexttrack', () => {
        console.log('navigator.mediaSession.nexttrack');
        dispatch('toggleMusic', { isNext: true })
      });
    }
  }
}

const getters = {

}

export default {
  state,
  mutations,
  actions,
  getters
}